import './styles/Terms.css';

import PrivacyPolicyBodyClientShopper from './PrivacyPolicyBodyClientShopper';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '1920px',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PrivacyPolicyBody() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PrivacyPolicyBodyClientShopper />
    </div>
  );
}
